.productCartWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
    gap: 15px;
    /* border: 1px solid black; */
    padding: 10px 10px 10px 10px;
    box-shadow: rgba(40, 80, 82, 0.25) 0px 14px 28px, rgba(40, 80, 82, 0.22) 0px 10px 10px;
}

.cartImageWrapper {
    min-width: 100px;
    position: relative;
    min-width: 80px;
    min-height: 80px;
}

.cartProducName {
    font-size: 14px;
    word-break: break-all;
}

.cartProductQty {
    display: flex;
    padding-top: 10px;
    align-items: center;
    justify-content: space-between;
}

.cartProductImage {
    max-height: 80px;
    max-width: 80px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
}

.incrementAndDecrementButtons {
    padding: 5px 10px 5px 10px;
}
/* 
.productCartDeleteBtn {
    margin-left: 10px;
    margin-right: 20px;
} */

.orderBtnWrapper {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.orderBtn {
    background-color: rgb(40, 80, 82);
    color: white;
    border-radius: 15px;
    border: none;
    padding: 10px 20px 10px 20px;
}

.cartOrderedAndFinalQty {
    font-size: 16px;
    font-weight: bold;
    min-width: 45px;
}

.cartProductOpt {
    font-size: 14px;
    font-weight: bold;
    margin-top: 10px;
}

.cartLineProduct {
    width: 100%;
}

.cartLineWrapper {
    display: flex;
    justify-content: space-between;
}

.cartProductDataWrapper {
    padding-right: 15px;
}
.cartBtnWrapper {
    display: flex;
    width: 40%;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    font-size: 14px;
}
