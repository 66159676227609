.productItemWrapper {
    display: flex;
    flex-direction: column;
    font-size: 10px;
    justify-content: center;
    align-items: center;
    max-width: 170px;
    text-align: center;
    height: fit-content;
    box-shadow: rgba(40, 80, 82, 0.25) 0px -0.2px 2px, rgba(40, 80, 82, 0.22) 0px 10px 10px;
    padding: 0px 7px;
    border-radius: 15px;
    margin-bottom: 15px;
    word-break: break-word;
}

/* .propsWrapper {
    width: 100%;
    max-height: 150px;
} */

.productImage {
    max-width: 85%;
    max-height: 85%;
}

.productOpt {
    width: 70px;
    min-height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    word-break: break-word;
    line-height: 11px;
}

.itemPrice {
    font-size: 20px;
    padding: 10px;
    font-weight: 900;
    color: #e55c3a;
    margin-bottom: 7px;
}

.itemPriceDisabled {
    font-size: 20px;
    padding: 10px;
    font-weight: 900;
    color: gray;
    margin-bottom: 7px;
}

.active {
    /* background-color: rgb(40, 80, 82); */
    color: rgb(40, 80, 82);
    border-bottom: 1px solid #e55c3a;
    border-radius: 20px;
    width: 70px;
    min-height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 11px;
}
.productProps {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    align-items: center;
    gap: 10px;
}

.addToCartBtn {
    width: 75px;
    height: 30px;
    background-color: rgb(40, 80, 82);
    color: white;
    border-radius: 15px;
    border: none;
}

.ordererQty {
    align-self: center;
    min-width: 12px;
}

.productOptWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}
.productImageWrapper {
    display: flex;
    height: 150px;
    justify-content: center;
    align-items: center;
}

.incrementAndDecrementButtons {
    padding: 5px 8px 5px 8px;
    background-color: rgb(40, 80, 82);
    color: white;
    border-radius: 15px;
    border: none;
}

.notVisibleProduct {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    display: none;
}

.notVisibleProductFlying {
    position: absolute;
    left: 0;
    top: 0;
    animation: fly 5s ease-in-out forwards;
    z-index: 100;
    display: block;
}

.incAndDecBtnsWrapper {
    display: flex;
    gap: 5px;
    justify-content: space-between;
}
