.categoriesWrapper {
    /* display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 15px;
    text-align: center;
    padding-top: 70px; */
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 15px;
    text-align: center;
    padding-top: 70px;
}

.categoryContainer {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
    border-radius: 20px;
    height: fit-content;
    box-shadow: rgba(40, 80, 82, 0.25) 0px 14px 28px, rgba(40, 80, 82, 0.22) 0px 10px 10px;
    padding: 7px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    font-size: 10px;
    justify-content: center;
    align-items: center;
    max-width: 170px;
    text-align: center;
}

.categoryImage {
    padding: 10px;
    /* height: 150px; */
    display: block;
    border-radius: 15px;
}
.categoryName {
    margin: 7px;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 0px;
}

.categoryImageWrapper {
    width: 150px;
    height: 150px;
}
