.completedOrderWrapper {
    text-align: center;
    padding-top: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.completedOrderWrapper > h2 {
    margin-bottom: 10%;
}
.completedOrderWrapper > p {
    margin-bottom: 3%;
}
.completedOrderWrapper > p:last-of-type {
    margin-bottom: 15%;
    padding-left: 20%;
    padding-right: 20%;
}

.completedOrderNumber {
    font-weight: bold;
    text-decoration: underline;
}

.completedOrderBtnHome {
    padding: 0 10px 0 10px;
    height: 30px;
    background-color: rgb(40, 80, 82);
    color: white;
    border-radius: 15px;
    border: none;
}

.completedOrderBtnWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 5%;
}
