@import url("https://fonts.googleapis.com/css2?family=Nunito&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Nunito", sans-serif;
  color: rgb(40, 80, 82);
}

body {
  background-color: #ffffff;
  /* background-color: var(--tg-theme-bg-color); */
  /* color: var(--tg-theme-text-color); */
}
