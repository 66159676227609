header {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    background-color: rgb(40, 80, 82);
    position: relative;
    position: fixed;
    top: 0;
    left: 0;
    justify-content: space-between;
    z-index: 100;
}

.menuContainer {
    position: absolute;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 50px;
    background-color: rgba(0, 0, 0, 0.5);
}

.menuList {
    position: absolute;
    left: 0;
    top: -50px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    animation: slideIn 0.5s forwards;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    height: 101%;
    width: 70%;
    overflow: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
}
.menuList::-webkit-scrollbar {
    width: 0;
}

@keyframes slideIn {
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(0);
    }
}
.menuListItem {
    list-style: none;
    margin-bottom: 7%;
    padding-left: 40px;
}
.menuItem {
    text-decoration: none;
}

.toggleScroll {
    overflow: hidden;
}

.headerLogo {
    width: 150px;
    height: 50px;
    background-image: url("../../../public/headerLogo.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.menuListLogo {
    margin: 0 auto;
    width: 100%;
    height: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    background-color: rgb(40, 80, 82);
    /* background-image: url("../../../public/menuListLogo.png"); */
    /* background-size: cover; */
    /* background-position: center; */
    /* background-repeat: no-repeat; */
}

.menuListLogo > svg {
    width: 50%;
    height: 50%;
}
.menuListContacts {
    margin-bottom: 20px;
}

.menuListTitle {
    margin-bottom: 10%;
    padding-left: 20px;
    text-decoration: underline;
}

.menuListContactsTitle {
    margin-bottom: 20px;
    padding-left: 20px;
    text-decoration: underline;
}

.menuListCallItemsWrapper {
    display: flex;
    align-items: center;
    gap: 10%;
    padding-left: 40px;
}

.menuListCallItemLink {
    text-decoration: none;
    padding-top: 2px;
    display: inline-block;
}
